<template>
    <div class="view-welcome">
        <h2>{{ website.wel.title }}</h2>
        <span :class="['actor', { typeing: isText }]">{{ text }}</span>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "Welcome",
    data() {
        return {
            DATA: [],
            count: 0,
            actor: "",
            text: "",
            isText: false
        };
    },
    computed: {
        ...mapGetters(["website"])
    },
    created() {
        this.DATA = this.website.wel.list;
        this.actor = this.DATA[this.count] || "";
        setTimeout(() => {
            this.isText = true;
            this.setData();
        }, 2000);
    },
    methods: {
        getData() {
            if (this.count < this.DATA.length - 1) {
                this.count++;
            } else {
                this.count = 0;
            }
            this.isText = true;
            this.actor = this.DATA[this.count];
        },
        setData() {
            let num = 0;
            let count = 0;
            let active = false;
            let timeoutstart = 5000;
            let timeoutend = 1000;
            let timespeed = 10;
            setInterval(() => {
                if (this.isText) {
                    if (count == this.actor.length) {
                        active = true;
                    } else {
                        active = false;
                    }
                    if (active) {
                        num--;
                        this.text = this.actor.substr(0, num);
                        if (num == 0) {
                            this.isText = false;
                            setTimeout(() => {
                                count = 0;
                                this.getData();
                            }, timeoutend);
                        }
                    } else {
                        num++;
                        this.text = this.actor.substr(0, num);
                        if (num == this.actor.length) {
                            this.isText = false;
                            setTimeout(() => {
                                this.isText = true;
                                count = this.actor.length;
                            }, timeoutstart);
                        }
                    }
                }
            }, timespeed);
        }
    }
};
</script>
<style lang="scss" scoped>
$fontColor: #333;

.view-welcome {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: $fontColor;
    & > h2 {
        font-size: 20px;
    }
    & > .actor {
        font-size: 18px;
        &:after {
            content: "";
            width: 3px;
            height: 25px;
            vertical-align: -5px;
            margin-left: 5px;
            background-color: $fontColor;
            display: inline-block;
            animation: blink 0.4s infinite alternate;
        }
        &.typeing:after {
            animation: none;
        }
        @keyframes blink {
            to {
                opacity: 0;
            }
        }
    }
}
</style>
